import React from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '../Components/layout';
import Hero from '../Components/hero';
import styled from 'styled-components';
import theme from '../utils/theme';
import PlusImage from '../../static/img/+_FAQ.svg';
import MinusImage from '../../static/img/-_FAQ.svg';
import params from '../utils/params';

class FAQ extends React.Component {
  componentDidMount() {
    this._addStickyEventListener(document.querySelector('.faq_topic_list'));
  }

  _addStickyEventListener(elem) {
    const toggleScroll = e => {
      const currentOffset = e.getBoundingClientRect().top;
      const stickyOffset = parseInt(getComputedStyle(e).top.replace('px', ''));
      if (currentOffset <= stickyOffset && e.offsetHeight > window.innerHeight - 80) {
        e.style.maxHeight = window.innerHeight - 125 + 'px';
        e.style.overflowY = 'scroll';
      } else {
        e.style.maxHeight = '';
        e.style.overflowY = '';
      }
    };

    if (CSS.supports && CSS.supports('position', 'sticky')) {
      toggleScroll(elem);
      window.addEventListener('scroll', () => toggleScroll(elem));
    }
  }

  render() {
    const {questionResponse, heroResponse} = this.props.data;
    const hero = heroResponse.edges.find(edge => edge.node.frontmatter.type === 'hero');
    const faqList = questionResponse.edges.map(v => v.node);
    const faqTopicListUnique = faqList
      .map(o => o.frontmatter.topic)
      .filter((v, i, s) => s.indexOf(v) === i);

    const expandQuestion = e => {
      const faqElemBody = e.currentTarget;
      const topicElem = e.currentTarget.parentNode;
      const faqListElem = topicElem.parentNode.parentNode.getElementsByClassName('active');

      if (faqElemBody.classList.contains('active')) {
        faqElemBody.classList.remove('active');
      } else {
        for (const elem of faqListElem) {
          elem.classList.remove('active');
        }
        faqElemBody.classList.add('active');
      }
    };

    const convertToId = str =>
      str
        .toLowerCase()
        .split(' ')
        .join('_');

    return (
      <Layout>
        <Hero content={hero.node.html} />
        <FaqContainer>
          <FaqSideBar>
            <FaqList className="faq_topic_list">
              {faqTopicListUnique.map((topic, idx) => {
                return (
                  <li key={idx}>
                    <Link to={'/faq#' + convertToId(topic)}>{topic}</Link>
                  </li>
                );
              })}
            </FaqList>
          </FaqSideBar>
          <FaqContent className="faq_content">
            <ul>
              {faqTopicListUnique.map((topic, idx) => {
                return (
                  <li key={idx}>
                    <FaqTopicContainer>
                      <FaqTopic>
                        <a
                          href={'/faq#' + convertToId(topic)}
                          className="offset-anchor"
                          id={convertToId(topic)}>
                          {topic}
                        </a>
                      </FaqTopic>
                      {faqList
                        .filter(v => v.frontmatter.topic === topic)
                        .map((question, idxQ) => {
                          return (
                            <FaqItemContainer key={idxQ} onClick={expandQuestion}>
                              <FaqItemHeader>
                                <FaqQuestionText className="faq_question_text">
                                  {question.frontmatter.question}
                                </FaqQuestionText>
                                <FaqQuestionsOps>
                                  <ExtendButton className="faq_extend_btn" />
                                </FaqQuestionsOps>
                              </FaqItemHeader>
                              <FaqItemBody className="faq_item_body">
                                <FaqAnswer
                                  linksAsButtons={!!question.frontmatter.style_links_as_buttons}
                                  dangerouslySetInnerHTML={{__html: question.html}}
                                />
                              </FaqItemBody>
                            </FaqItemContainer>
                          );
                        })}
                    </FaqTopicContainer>
                  </li>
                );
              })}
            </ul>
          </FaqContent>
        </FaqContainer>
      </Layout>
    );
  }
}

export default FAQ;

export const pageQuery = graphql`
  query {
    heroResponse: allMarkdownRemark(filter: {frontmatter: {id: {eq: "faq"}, type: {eq: "hero"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
            id
          }
        }
      }
    }
    questionResponse: allMarkdownRemark(
      filter: {frontmatter: {id: {eq: "faq"}, type: {eq: "faq"}}}
      sort: {fields: [frontmatter___topicOrder, frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            type
            topic
            question
            style_links_as_buttons
          }
        }
      }
    }
  }
`;

const FaqSideBar = styled.div`
  width: 22%;
  min-width: 250px;
  max-width: 360px;
  margin-bottom: 20px;
  white-space: nowrap;
  a {
    font-size: 20px;
  }
`;

const FaqList = styled.ul`
  position: sticky;
  top: 100px;
  margin-right: 10px;
  li {
    margin-bottom: 16px;
    a {
      padding-left: 13px;
    }
  }
  li.active {
    border-left: 2px solid ${theme.primaryLight};
  }
`;

const FaqTopicContainer = styled.ul`
  margin-bottom: 78px;
`;

const FaqContent = styled.div`
  margin: 0 auto;
  width: 78%;
`;

const FaqContainer = styled.div`
  background: ${theme.textWhite};
  margin: 0 auto;
  max-width: 1600px;

  @media (max-width: 1999px) {
    max-width: 1200px;
  }

  @media (max-width: 1439px) {
    max-width: 900px;
    align-items: flex-start;
  }

  @media (max-width: 1023px) {
    max-width: 700px;
  }

  @media (max-width: 767px) {
    max-width: 280px;
  }

  padding: 112px 0 0;
  display: flex;
  a {
    text-decoration: none;
  }
  @media only screen and (${params.mediaQueryMaxWidthLarge}) {
    padding: 39px 0 0;
    ${FaqSideBar} {
      display: none;
    }
    ${FaqContent} {
      width: calc(100% - 68px) !important;
    }
  }
  @media only screen and (${params.mediaQueryMaxWidthLarge}) {
    ${FaqContent} {
      width: calc(100% - 35px) !important;
    }
  }
`;

const FaqTopic = styled.li`
  margin-bottom: 13px;
  a {
    color: ${theme.primaryLight};
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 30px;
    pointer-events: none;
    cursor: default;
  }
`;

const FaqItemContainer = styled.li`
  width: 100%;
  min-height: 76px;
  color: ${theme.textBlack};
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &.active .faq_item_body {
    display: block;
  }
  &.active .faq_extend_btn {
    background-image: url(${MinusImage});
    background-color: ${theme.primaryBlue};
    border-radius: 25px;
  }
  &.active .faq_question_text {
    color: ${theme.primaryLight};
  }
  &:not(.active) {
    background-color: ${theme.primaryBlue};
    border-radius: 6px;
  }
`;

const FaqQuestionText = styled.div`
  width: calc(100% - 46px);
  height: 100%;
`;

const FaqQuestionsOps = styled.div`
  height: 100%;
  width: 46px;
  align-items: center;
  display: flex;
`;

const FaqItemHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  font-size: 22px;
  padding-left: 25px;
  z-index: 1;
  @media only screen and (${params.mediaQueryMaxWidthSmall}) {
    font-size: 18px;
  }
`;

const FaqItemBody = styled.div`
  width: 100%;
  display: none;
`;

const FaqAnswer = styled.div`
  max-width: 646px;
  padding: 12px 20px 20px;
  h3 {
    margin: 34px 0 20px;
  }

  ul {
    margin-bottom: 20px;
  }

  li {
    font-size: 18px;
    line-height: 29px;
    margin-left: 1em;
    :before {
      content: '\u2022';
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      color: ${theme.primaryLight};
    }
  }

  a {
    color: ${({linksAsButtons}) => (linksAsButtons ? theme.textBlack : theme.primaryLight)};
    border: ${({linksAsButtons}) => (linksAsButtons ? '1px solid' + theme.primaryLight : 'none')};
    background: ${theme.textWhite};
    text-decoration: none;
    border-radius: 20px;
    padding: ${({linksAsButtons}) => (linksAsButtons ? '8px 24px 12px' : '0')};
    font-size: 16px;
    line-height: 16px;
    transition: 100ms;
    display: ${({linksAsButtons}) => (linksAsButtons ? 'inline-block' : 'inline')};
  }

  a:hover {
    background: ${({linksAsButtons}) => (linksAsButtons ? theme.primaryLight : theme.textWhite)};
    color: ${({linksAsButtons}) => (linksAsButtons ? theme.textWhite : theme.primaryHover)};
  }

  a:active {
    background: ${({linksAsButtons}) => (linksAsButtons ? theme.primaryDark : theme.textWhite)};
    color: ${({linksAsButtons}) => (linksAsButtons ? theme.textWhite : theme.primaryClick)};
    border-color: ${theme.primaryDark};
  }
`;

const ExtendButton = styled.a`
  height: 46px;
  width: 46px;
  background-image: url(${PlusImage});
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
`;
